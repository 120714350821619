<template>
  <b-card
    id="speaking-add"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h2 class="mb-0">
          Thêm mới bài test Speaking
        </h2>

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Tiêu đề"
            rules="required"
          >
            <b-form-group
              label="Tiêu đề"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="testData.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Speaking Test tháng 01/2022"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="Loại"
            rules="required"
          >
            <b-form-group
              label="Loại"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="testData.plan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="Trạng thái"
            rules="required"
          >
            <b-form-group
              label="Trạng thái"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="testData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <div
            v-for="(section, id) in testData.speaking_sections"
            :key="id"
          >
            <b-card
              :title="section.title"
              style="border-style: solid;"
            >
              <b-form-group
                label="Bài đọc"
                label-for="section-content"
              >
                <quill-editor
                  id="section-content"
                  v-model="section.content"
                  :options="snowOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                />
              </b-form-group>
            </b-card>
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Lưu thông tin
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="resetForm"
            >
              Xóa dữ liệu
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-card>
</template>

<script>
import { required, alphaNum, email } from '@validations'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard,
} from 'bootstrap-vue'
import { PLAN_PREE, PLAN_PREMIUM, planOptions } from '@core/utils/plan'
import {
  TEST_STATUS_ACTIVE,
  TEST_STATUS_DRAFT,
  statusOptions,
  blankSpeakingSections,
} from '@core/utils/tests/test_constants'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import speakingStoreModule from "@/views/tests/speaking/speakingStoreModule";

export default {
  name: 'SpeakingAdd',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onEditorBlur(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorFocus(quill) {
    },
    // eslint-disable-next-line no-unused-vars
    onEditorReady(quill) {
      window.Quill.register('modules/imageResize', ImageResize)
    },
  },
  setup(props, { emit }) {
    const TEST_SPEAKING_STORE_MODULE_NAME = 'test-speaking'

    // Register module
    if (!store.hasModule(TEST_SPEAKING_STORE_MODULE_NAME)) store.registerModule(TEST_SPEAKING_STORE_MODULE_NAME, speakingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TEST_SPEAKING_STORE_MODULE_NAME)) store.unregisterModule(TEST_SPEAKING_STORE_MODULE_NAME)
    })
    const { route } = useRouter()

    const blankSpeakingTestData = {
      title: '',
      plan: route.value.query.plan === PLAN_PREMIUM ? PLAN_PREMIUM : PLAN_PREE,
      status: TEST_STATUS_DRAFT,
      speaking_sections: blankSpeakingSections,
    }

    const testData = ref(JSON.parse(JSON.stringify(blankSpeakingTestData)))
    const resetTestData = () => {
      testData.value = JSON.parse(JSON.stringify(blankSpeakingTestData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTestData)

    const onSubmit = () => {
      console.log('SUBMIT')
      store.dispatch('test-speaking/createTest', testData.value)
        .then(() => {
          emit('refetch-data')
        })
    }

    return {
      testData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      planOptions,
      statusOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
