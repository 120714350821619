import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSpeakingFree(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/speakings/free', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchSpeakingPremium(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/speakings/premium', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    createTest(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/speakings', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSpeakingTest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/speakings/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
